import { Component, OnInit } from '@angular/core';
import { ProjectData } from '../../../Models/ProjectData';
import { environment } from '../../../environments/environment';
import { ProjectRepository } from '../../../Repositories/ProjectRepository';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  init = false;
  title = 'dividebyzerofrontend';
  environment: any;
  projects: ProjectData[];

  constructor(projectRepository: ProjectRepository) {
    this.projects = projectRepository.list('');
    this.environment = environment;

    this.init = true;
  }
}
