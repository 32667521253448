<mat-card class="site" *ngIf="!environment.maintainance">
  <mat-card-header class="site-header">
    <!-- <div mat-card-avatar class="site-icon"></div> -->
    <mat-card-title class="site-title">Divide By ZerØ StudiØ</mat-card-title>
    <mat-card-subtitle class="site-subtitle">Coding and Things</mat-card-subtitle>
  </mat-card-header>
  <div class="site-section nav-bar">
    <app-project-navbar></app-project-navbar>
  </div>
  <div class="site-welcome-section">
  </div>
  <mat-card-content>
    <router-outlet></router-outlet>
  </mat-card-content>
  <mat-card-footer>
    <p class="site-footer">Thats All Folks.</p>
  </mat-card-footer>
</mat-card>
<app-coming-soon *ngIf="environment.maintainance"></app-coming-soon>