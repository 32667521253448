import { Injectable } from '@angular/core';
import { IRepository } from './IRepository';
import { ProjectData } from '../Models/ProjectData';
import { ProjectTypeEnum } from '../Models/ProjectTypeEnum';

@Injectable()
export class ProjectRepository implements IRepository {

  list(filter: string): ProjectData[] {
    let results = [
      {
        position: 'left',
        title: 'Tiny Tanks Warfare',
        tags: [ProjectTypeEnum.App, ProjectTypeEnum.Game],
        description: 'Tank fighting game downloaded over 50,000 times.',
        featureImage: 'http://dividebyzerostudio.com/assets/images/TinyTanksLogo.png',
        url: 'https://www.youtube.com/watch?v=tJZIQt0MM4Y&t=8s&ab_channel=DivideByZero'
      },
      {
        position: 'right',
        title: 'Hot Air Balloon Dash',
        tags: [ProjectTypeEnum.App, ProjectTypeEnum.Game],
        description: 'An infinite side scroller inspired by flappy birds',
        featureImage: 'http://dividebyzerostudio.com/assets/images/HotAirBalloonDash.png',
        url: 'https://play.google.com/store/apps/details?id=com.DivideByZero.HotAirBalloonDash&hl=en&gl=US'
      },
      {
        position: 'right',
        title: 'MWT',
        tags: [ProjectTypeEnum.Website],
        description: 'Fashion website template designed in wordpress',
        featureImage: 'http://dividebyzerostudio.com/assets/images/menswear.png',
        url: 'http://menswearteam.com/'
      },
      {
        position: 'right',
        title: 'Scientific Informer',
        tags: [ProjectTypeEnum.Website],
        description: 'A proof of concept website similar to ifls',
        featureImage: 'http://dividebyzerostudio.com/assets/images/scientific-informer-screenshot.png',
        url: 'http://www.scientificinformer.com/'
      },
      {
        position: 'right',
        title: "Pam's School of motoring",
        tags: [ProjectTypeEnum.Website],
        description: 'Website designed for a driving school',
        featureImage: 'http://dividebyzerostudio.com/assets/images/pams-school-of-motoring.png',
        url: 'http://www.4psm.co.uk/'
      }
    ];

    if (filter) {
      return results.filter(_ => Object.values(_).find(p => p.toString().toLowerCase().includes(filter.toLowerCase())));
    }

    return results;
  }

  show(id: string) {
    // TODO: Implement Function
  }

  create(body: any) {
    // TODO: Implement Function
  }

  update(id: any) {
    // TODO: Implement Function
  }

  delete(id: any) {
    // TODO: Implement Function
  }
}
