import { Component, OnInit, Input, Host, HostBinding } from '@angular/core';
import { ProjectData } from '../../../Models/ProjectData';

@HostBinding('class.project-card')
@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})
export class ProjectCardComponent implements OnInit {
  @Input()
  project: ProjectData;

  constructor() {

  }

  ngOnInit() {
  }

}
