import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Router
import { AppRouterModule } from './app-router.module';
// Angular Material design components
import { AppMaterialModule } from './app-material.module';

// Repositories
import { ProjectRepository } from 'src/Repositories/ProjectRepository';

// Project Components
import { AppComponent } from './components/app-component/app.component';
import { ProjectCardComponent } from './components/project-card-component/project-card.component';
import { ProjectNavbarComponent } from './components/project-navbar/project-navbar.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { BlogComponent } from './components/blog-component/blog.component';
import { BlogListingComponent } from './components/blog-listing/blog-listing.component';
import { HomeComponent } from './components/home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    ProjectCardComponent,
    ProjectNavbarComponent,
    ComingSoonComponent,
    BlogComponent,
    BlogListingComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRouterModule,
    AppMaterialModule,
  ],
  providers: [
    ProjectRepository
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
