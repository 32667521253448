import { Component } from '@angular/core';
import { ProjectData } from 'src/Models/ProjectData';
import { environment } from '../../../environments/environment';
import { ProjectRepository } from 'src/Repositories/ProjectRepository';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  init = false;
  title = 'dividebyzerofrontend';
  environment: any;
  projects: ProjectData[];

  constructor() {
    this.environment = environment;

    this.init = true;
  }
}
