<div id="about-section" class="site-section">
  <p class="site-section-title">About</p>
  <p class="site-section-text">I hope you enjoy looking around at some of the work i have done. Below is an list of projects, games and coding bits that
    i have done. There is a mixture of hobby and commercial projects that i have worked on. This website is also an ongoing
    project, i aim to update/muse on the world of tech and share some helpful information.</p>
</div>
<div class="site-section">
  <p class="site-section-title">Projects</p>
</div>
<div id="projects-section" class="project-area">
  <app-project-card *ngFor="let project of projects" [project]="project"></app-project-card>
</div>