import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-navbar',
  templateUrl: './project-navbar.component.html',
  styleUrls: ['./project-navbar.component.css']
})
export class ProjectNavbarComponent implements OnInit {
  links: any;

  constructor() {
    this.links = [
      { text: 'About', anchor: "/", fragment: "about-section" },
      { text: 'Projects', anchor: '/', fragment: "projects-section" },
      { text: 'Blog', anchor: '/blog', fragment: "" },
    ];
  }

  ngOnInit() {
  }

}
