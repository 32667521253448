import { ProjectTypeEnum } from "./ProjectTypeEnum";

export class ProjectData {
  title: string;
  url: string;
  position: string;
  tags: ProjectTypeEnum[];
  description: string;
  featureImage: string;
}
